<template>
  <v-main>
    <v-container>
      <h1 class="display-1">Septic Systems</h1>
      <div>
        <div>
          <p>
            Truro's drinking water is protected by its septic systems and its
            nitrogen loading limitations by not putting too much wastewater into
            the ground per acre. This helps keep the level of nitrate and other
            contaminants in our aquifer and hence in our well water at a safe
            level.
          </p>
          <p>
            As a result it is important that all home owners in Truro properly
            maintain their septic systems so that they can help manage the
            nitrogen loading onto our sole-source aquifer just a few feet below
            every septic system. Improperly maintained septic systems can fail,
            releasing additional loads into the aquifer, and raising the level
            of contamination of the neighborhood.
          </p>
          <p>
            The Septic feature of the Peeps Portal is designed to let you easily
            track your septic system maintenance records and results. And by
            doing so, Peeps will be able to help you more easily make sure that
            your septic system is operating as designed. It’s a very simple
            entry of a few pieces of data from your period septic pumping
            (usually every 3 to 5 years), and retaining that pumping information
            will give you a good record of your system's health. By keeping your
            septic system operating well, it will have a beneficial effect
            beneath ground to your neighborhood's drinking water wells.
          </p>
        </div>
        <div class="d-flex justify-center">
          <v-img
            src="@/assets/img/SepticSystem.png"
            class="septic-system"
            max-width="768"
          ></v-img>
        </div>
        <div>
          <h4>RESOURCES:</h4>
          <p class="mt-3">
            Be Septic Smart! |
            <a
              href="https://www.epa.gov/sites/production/files/2018-05/documents/septicsmart_infographic_052318.pdf"
            >
              EPA</a
            >
          </p>

          <p>
            How to Care for Your Septic System |
            <a href="https://www.epa.gov/septic/how-care-your-septic-system">
              EPA
            </a>
          </p>
          <p>
            System Pumping Record |
            <a
              href="https://www.mass.gov/doc/system-pumping-record-title-5/download"
              >MassDEP
            </a>
          </p>
        </div>
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'About Septic'
    }
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
  .septic-system {
    max-width: 375px !important;
  }
}
</style>
